// Show element when there is visible on the bottom of the browser after scrolling down the page
// The function is executed once

document.addEventListener("DOMContentLoaded", () => {
  const fadeElementAfterScroll = (element) => {
    const elementOffset = element.getBoundingClientRect().top + (window.scrollY / 2);
    const elementHeight = element.offsetHeight;
    const elementIsHidden = element.getAttribute('data-hidden');
    const scrolledHeight = window.pageYOffset;
    const browserHeight = window.innerHeight;

    if (scrolledHeight + browserHeight - elementHeight >= elementOffset) {
      elementIsHidden && element.setAttribute('data-hidden', false);
    }
  }

  window.addEventListener('scroll', () => {
    const elements = document.querySelectorAll('.jsFadeElementAfterScroll');

    elements.forEach(element => fadeElementAfterScroll(element));
  });
});
